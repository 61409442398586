<template>
  <div>
    <page-title :title="`Bidbonds`">
      <template slot="button" v-if="$can('bidbonds.store')">
        <router-link
          :to="{ name: 'bidbonds.create' }"
          class="btn btn-primary btn-sm float-right" 
          style="color:white !important"
        >
          <i class="lni-plus mr-2"></i> Generate Bidbond
        </router-link>
      </template>
    </page-title>
    <div class="card">
      <div class="card-body">
        <table class="table  table-striped bg-white">
          <thead>
            <tr>
              <th>#</th>
              <th>BidBond Number</th>
              <th>Company</th>
              <th>Counter Party</th>
              <th>Amount</th>
              <th>Effective Date</th>
              <th>Period</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(bond, i) in bidbonds" :key="`bidbond-${i}`">
              <td>{{ i + 1 }}</td>
              <td>{{ bond.bidbond_secret }}</td>
              <td>{{ bond.company_name }}</td>
              <td>{{ bond.counterparty_name }}</td>
              <td>{{ bond.tender_amount }}</td>
              <td>{{ bond.tender_effective_date }}</td>
              <td>{{ bond.tender_period }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'bidbonds.show',
                    params: { id: bond.bidbond_secret },
                  }"
                  class="btn btn-sm btn-info"
                  style="color:white !important"
                >
                  <i class="lni-eye mr-2"></i> view
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      bidbonds: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.$axios
        .get("/api/v1/dashboard/bidbonds")
        .then((response) => {
          this.bidbonds = response.data;
          setTimeout(() => {
            this.$loader.stop();
            $("table").DataTable();
          }, 1000);
        })
        .catch((error) => {
          this.$http(error.response);
          this.$loader.stop();
        });
    },
  },
};
</script>
